.pagination li a {
  padding: 4px 4px;

  background: var(--white) !important;
  color: var(--color1) !important;
  /* color: red; */
  width: 32px;
  text-align: center;
  border-right: 1px solid var(--color1);
  display: block;
  cursor: pointer;
}

.pagination {
  margin: 0;
  /* margin-left: 20px; */
  z-index: 200 !important;
  border: 1px solid var(--color1);
  /* border: 1px solid black; */
  border-radius: 5px;
  overflow: hidden;
}

.pagination li:nth-last-child(1) a {
  border-right: 0;
}

.pagination li.active a {
  background: var(--color1) !important;
  color: var(--white) !important;
}

.table_border {
  margin-left: 20px;
  width: fit-content;
  border: 1px solid black;
  border-radius: 5px;
}

.chat_message_reply {
  padding-left: 25px;
  margin-left: 70%;
  font-size: 12px;
}
.reply_right {
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #d20400;
  color: white;
}

.address {
  box-shadow: 0px 3px 6px #00000029;
}

.tab-switch {
  display: flex;
  justify-content: center;
}
.tab-switch-01 {
  margin-top: 15px;
  width: auto;
  min-width: 210px;
  margin-inline: 5px;
}
.tab-switch-02 {
  margin-top: 15px;
  width: auto;
  min-width: 210px;
  margin-inline: 5px;
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  color: #ffffff !important;
  background-color: #d20400 !important;
  /* border-color: #494f57 !important; */
}

.vertical {
  /* writing-mode: "vertical-ltr"; */
  /* text-orientation: inherit; */
  display: flex;
  justify-content: flex-end;
  /* writing-mode: vertical-lr; */
  /* transform: rotate(180deg); */

  font-size: 14px;
  font-weight: 900;
  color: #000000;
}

.verticalContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* simple arrow -> start */
.arrow {
  width: 120px;
}

.line {
  margin-top: 9px;
  margin-left: 15px;
  width: 50px;
  background: #d20400;
  height: 4px;
  float: left;
}

.point {
  width: 0;
  height: 0;
  margin-top: 1.5px;

  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 20px solid #d20400;
  float: left;
}
/* simple arrow -> end */

.j_c {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.noti_btn {
  font-size: 12px;
  background-color: #dc3545;
  padding-left: 12px;
  padding-right: 12px;

  color: white;
  border: none;
}

.txt-rigth {
  text-align: right;
  display: block;
  /* float: left; */
  /* font-weight: 600; */
  padding-right: 55px;
}

.sticky-header {
  position: sticky;
  background-color: #fff;
  width: 100%;
  z-index: 9999;
}

.otp-box {
  height: 50px;
  color: #000;
  border-radius: 0;
  padding: 0 15px;
  line-height: 1em;
  font-size: 15px;
  width: 50px;
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #f6f8f9;
  border: 1px solid #f6f8f9;
}

.colorRed {
  color: red;
}

.main-modal {
  width: 33%;
}

.rb_button {
  border-radius: 50%;
  padding: 0.4em;
  width: 31px;
  height: 30px;
  border: 2px solid rgb(218, 34, 34);
  color: rgb(218, 34, 34);
  position: sticky;
  margin-left: 86px;
}
.rb_button:hover {
  border: 2px solid rgb(218, 34, 34);
  background-color: rgb(0, 0, 0);
  color: rgb(218, 34, 34);
}
.rb_button:before {
  content: " ";
  position: absolute;
  display: block;
  background-color: rgb(218, 34, 34);
  width: 2px;
  left: 12px;
  top: 5px;
  bottom: 5px;
  transform: rotate(45deg);
}
.rb_button:after {
  content: " ";
  position: absolute;
  display: block;
  background-color: rgb(218, 34, 34);
  height: 2px;
  top: 12px;
  left: 5px;
  right: 5px;
  transform: rotate(45deg);
}

.hrline {
  /* display: "flex"; */
  margin: 0 auto;
  color: rgb(175, 22, 22);
  background-color: rgb(175, 22, 22);
  border-color: rgb(175, 22, 22);
  /* margin-top: 5px; */
  top: 30px;
  width: 80%;
  height: 5px;
  justify-content: center;
}

.vegtype {
  width: 17px;
  height: 17px;
  border: 1px solid #e1e1e1;
  padding: 3px;
  position: relative;
  margin-right: 10px;
}

.vegtype:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #dfdfdf;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.vegtype.veg {
  border-color: #8bc34a;
  background-color: white;
}
.vegtype.veg:before {
  background: #8bc34a;
}
.vegtype.non-veg {
  border-color: #f44336;
  background-color: white;
}
.vegtype.non-veg:before {
  background: #f44336;
}

.veg_right_pad {
  padding-right: 25px;
}

.Toastify__toast-container {
  z-index: 90000000;
  /* --toastify-z-index: 99999999; */
}

.GoBackClass {
  color: #d20400;
  cursor: pointer;
  font-weight: 600;
}

.headerImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.customerImage {
  width: 80px;
  height: 40px;
  /* border-radius: 40%; */
  object-fit: contain;
}

.displayItem {
  display: block !important;
  opacity: 1 !important;
  /* background-color: #8bc34a; */
}

.green_toggle_color {
  background-color: #5bd200 !important;
}

.attachment_replyTicket {
  width: 58px;
  height: 49px;
  background: #f6f8f9 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgReplies {
  width: 100%;
  padding: 3px;
  background-color: gray;
  border-radius: 3px;
}

.projRedColor {
  color: #d20400;
}

.projBlackColor {
  color: #000000 !important;
}

.ql-hidden {
  display: none !important;
}

.ql-editor {
  pointer-events: none;
}

.ql-clipboard {
  display: none;
}

.amount-column {
  display: flex;
  justify-content: flex-end;
}
/* .after_login ul li.notice i::before{
  background-color: white;
} */
/* header .after_login ul li.notice i::before{ */
/* background-color: red;  */
/* display: none; */
/* } */

/* header .after_login ul li.notice i::after{
 display: none;
} */
header .after_login ul li.notice i.bell::after {
  display: none;
}

.trim_Name {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d_f {
  display: flex;
}

.jc_c {
  justify-content: center;
}

.ai_c {
  align-items: center;
}

.colored-rating {
  color: #dcad00;
}

.colored-rating {
  color: #dcad00;
}
.colored-rating span.far {
  color: #aaaaaa;
}
