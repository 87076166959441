.product_management table tr td.content ul li.heading a {
  color: #d20400;
}
.tabs03 .nav-tabs a {
  width: 180px;
  text-align: center;
}
.pro-sidebar .pro-menu {
  padding: 0;
  background: transparent;
}

.pro-sidebar > .pro-sidebar-inner {
  background: transparent;
  /* width: 100%; */
}
/* done by deepak  as the pro sidebar gets transparent when we see in smaller view */

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-item {
  padding: 12px 15px;
  color: #fff;
}
.dashbord_outer .dashbord_left ul li a.isActive {
  background-color: #d20400;
}
.change_pass_outer .comman_from nav {
  width: 100%;
}

.comman_from .tab-content {
  width: 100%;
}
.order_pickup_outer {
  min-height: auto;
}
.order_Confirmed {
  min-height: auto;
}
.modal-gray.modal {
  background-color: #f6f8f9;
}
.errorMsg {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  width: 100%;
}
form .fileright input[type="file"] {
  height: 30px;
}
.changImage {
  position: relative;
  overflow: hidden;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

.changImage input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}
.dashbord_outer .dashbord_right .editsession {
  flex-wrap: wrap;
  text-align: center;
}
.dashbord_outer {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  min-height: 300px;
}

.mainPageWraper {
  width: 100%;
}
header .after_login ul li.notice .dropdown-menu {
  width: 500px;
  padding: 0;
  border: 0;
}
header .after_login ul li.notice .dropdown-toggle::after {
  display: none;
}
.Withdraw_outer {
  min-height: auto;
  padding: 0;
}
.Withdraw_outer .Withdraw_inner {
  max-width: 100%;
}
.Withdraw_outer .Withdraw_inner .cus_btn {
  margin: 15px auto 0;
}
form input,
form textarea {
  color: #000;
  border-radius: 0;
  padding: 15px 15px;
  line-height: 1em;
  font-size: 15px;
  width: 100%;
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #f6f8f9;
  border: 1px solid #f6f8f9;
}
form .user_image input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
}

.user_image {
  position: relative;
}
.dashbord_outer .dashbord_left {
  padding-bottom: 70px;
}
.release {
  font-size: 12px;
  color: #fff;
  letter-spacing: 2px;
  text-align: center;
}
.profielUpld input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}

.profielUpld {
  overflow: hidden;
}
.fileleft {
  width: 70%;
}

.fileright {
  width: 30%;
  padding-left: 10px;
  padding-top: 26px;
}
.fileright button {
  width: auto;
  min-width: 100px;
}
.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

.react-confirm-alert-body > h1 {
  font-size: 24px !important;
  margin-bottom: 21px !important;
}

.react-confirm-alert-body {
  font-size: 14px;
  text-align: center !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

@media (max-width: 752px) {
  .pro-sidebar > .pro-sidebar-inner {
    background: black !important;
    width: 100%;
  }
}
/* added by deepak as the side bar getting transparent in the smaller view */

.comman_from span.resend {
  text-align: right;
  font-size: 12px;
  cursor: pointer;
}



.notifyHead h4 {
  font-size: 14px;
  margin: 0;
}

.notifyTimer {
  font-size: 12px;
}

.notifyHead {
  margin-bottom: 9px;
}

.notifyDescrp p {
  font-size: 14px;
  margin-bottom: 0;
}

.notifyInside {
  max-height: 50vh;
  overflow: auto;
}

.notification_popup_inner .custom01 {
  width: 100%;
  border-radius: 0;
}
.notification_outer {
  width: 100%;
}

.oracabtn .custom01.black {
  background: #252525;
  border-color: #252525;
}
.oracabtn .custom01.black:hover {
  background: #000;
  border-color: #000;
}
.order_view_outer ul.ordr_status {
  display: flex;
  display: -webkit-flex;
  border-top: 0 solid #e3e3e3;
  margin-top: 30px;
  position: relative;
  z-index: 1;
  align-items: flex-start;
  width: 100%;
}
.order_view_outer .order_detail ul li.unactive::after{
  content: none;
}
.order_view_outer ul.ordr_status:before {
  content: "";
  position: absolute;
  background: #e3e3e3;
  left: 13%;
  right: 13%;
  height: 2px;
  top: -2px;
}
.order_view_outer ul.ordr_status li {
  font-size: 13px;
  width: 25%;
  position: relative;
  padding-top: 24px;
  padding: 24px 15px 0px;
  text-align: center;
}

.order_view_outer ul.ordr_status li i {
  position: absolute;
}

.order_view_outer ul.ordr_status li i {
  position: absolute;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #e1e1e1;
  top: -15px;
  background: #fff;
  color: #ababab;
  margin: 0;
}

.order_view_outer ul.ordr_status li:before {
  content: '';
  background: #fff;
  position: absolute;
  left:0;
  right: 0;
  height: 2px;
  top: -2px;
  z-index: -2;
}

.order_view_outer ul.ordr_status li.active:before {
  background: #000;
  z-index: 0;
}

.order_view_outer ul.ordr_status li.active i {
  color: #fff;
  background: #000;
  border-color: #fff;
}
.order_view_outer ul.ordr_status li:nth-child(1):before {
  width: 50%;
  left: 50%;
}
.order_view_outer ul.ordr_status li p {
  font-size: 10px;
}
.order_view_outer ul.ordr_status li:nth-last-child(1):before {
  width: 50%;
}
.order_view_outer ul.ordr_status.CANCELLED:before {
  right: 75%;
}
.order_view_outer ul.ordr_status.REJECTS:before {
  right: 75%;
}
.order_view_outer ul.ordr_status.REJECTED:before {
  right: 75%;
}
.highlighted {
  box-shadow: 0 0 10px #00000085;
}

.highlighted:before {
  position: absolute;
  border-style: solid;
  border-width: 5px;
  border-color: #fff;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .5s;
  transition: .5s;
  -webkit-animation: scale 2s linear 0s infinite;
  animation: scale 2s linear 0s infinite;
  z-index: -2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlighted:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 7px;
  border-color: #fff;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .5s;
  transition: .5s;
  -webkit-animation: scale 2s linear 1s infinite;
  animation: scale 2s linear 1s infinite;
  z-index: -2;
  transform: scale(1);
}

@keyframes scale {
  from {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1
  }
  to {
      -webkit-transform: scale(1.8);
      transform: scale(1.8);
      opacity: 0
  }
}
.Toastify {
  z-index: 999999;
}
.heaDerAdress {
  width: calc(100% - 480px);
}

.logo {
  width: 180px;
}

.after_login {
  width: 300px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
}
header ul li a {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
}







