@font-face {
    font-family: 'SF Pro';
    src: url('../font/fontsfree-net-sfprotext-light-webfont.eot');
    src: url('../font/fontsfree-net-sfprotext-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/fontsfree-net-sfprotext-light-webfont.woff2') format('woff2'),
         url('../font/fontsfree-net-sfprotext-light-webfont.woff') format('woff'),
         url('../font/fontsfree-net-sfprotext-light-webfont.ttf') format('truetype'),
         url('../font/fontsfree-net-sfprotext-light-webfont.svg#sf_pro_textlight') format('svg');
    font-weight: normal;
    font-style: normal;

}
*{
    font-family: 'SF Pro';
}


body{
    font-family: 'SF Pro';
}
h1,h2,h3,h4,h5,h6{
    font-family: 'SF Pro';
} 
a,span,ul,li{
    font-family: 'SF Pro' !important;
}


article{
    display: grid; 
    grid-template-columns: 1fr 3fr;
    row-gap: 12px;
    column-gap: 12px;
}
.bd-example {
    background: #f8f9fa;
    padding: 25px;
    margin: 30px 0 25px;
}

/*=============================================================
                    01
============================================================= */
.slider{
    position: relative;
    height: 650px;
    background-image: url('../images/slider.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    background-blend-mode: multiply;
}
  

.signup_outer{
    position: relative;
    overflow: hidden;
}
.signup_outer::after{
    display: block;
    content: "";
    position: absolute;
    background-image: url(../images/d.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    width: 930px;
    height: 650px;
    bottom: -80px;
    right: 0;
    z-index: 0;
}
.signup_outer .container{
    position: relative;
    z-index: 99;
}

.footer_outer{
    position: relative;
}
.footer_outer::after{
    display: block;
    content: "";
    position: absolute;
    background-image: url(../images/footer-bg.png);
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 40px;
    top: -39px;
    right: 0;
    z-index: 0;
    animation: slide 50s linear infinite;
    -webkit-animation: slide 50s linear infinite;
}
@keyframes slide{
    from{background-position:0 0}
    to{background-position:1920px 0}
  }
  @-webkit-keyframes slide{
    from{background-position:0 0}
    to{background-position:1920px 0}
}


